import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { HTMLContent } from '../../components/Content'
import BackgroundImage from '../../components/BackgroundImage'
import { ImageOverlay } from '../../utils'
import PageHelmet from '../../components/PageHelmet'

export const JobPostTemplate = ({
  content,
  heroImageObject,
  title,
  description
}) => {
  return (
    <div className="page-container container content blog-post-container">
      <PageHelmet seoObject={{ metaTitle: title, metaDescription: description }} />
      <div className="full-width-padding">
        <BackgroundImage classes={"page-header-image"} imageInfo={heroImageObject.image}>
          <div className="image-title">
            <hr />
            {title}
          </div>
          <ImageOverlay overlay={heroImageObject.overlay} />
        </BackgroundImage>
      </div>
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <HTMLContent content={content} className="blog-content" />
          </div>
        </div>
      </div>
    </div>
  )
}

const JobPost = ({ data, location }) => {
  const { post, image } = data;
  return (
    <Layout>
      <JobPostTemplate
        location={location}
        content={post.html}
        seoObject={post.frontmatter.seoObject}
        contentComponent={HTMLContent}
        heroImageObject={ image.frontmatter.heroImageObject }
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

JobPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default JobPost

export const pageQuery = graphql`
  query JobPostByIDJP($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
     image: markdownRemark(frontmatter: { templateKey: { eq: "careers-page-jp" } }) {
      frontmatter {
        heroImageObject {
          overlay
          image
        }
      }
    }
  }
`
